<template lang="html">
  <div id="firebaseui-auth-container" />
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Auth",
  computed: {
    ...mapGetters(["isLoggedIn"])
  },
  watch: {
    isLoggedIn() {
      if (this.isLoggedIn) {
        this.$router.push(`/`);
      }
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$router.push(`/`);
    } else {
      this.$store.dispatch("initFirebaseUi");
    }
  }
};
</script>
<style src="firebaseui/dist/firebaseui.css"></style>
